import areas from "@/assets/data/areas.json"
/**
 * 検索結果にヒットした文書数（都道府県コードcode、団体コードaffiliation_code別に集計した文書ヒット数）を格納する。
 */
export const aggregation = {
    state: () => ({
        areas: areas,
        document_count_by_area: [],
        document_count_by_affiliation_code: [],
        document_count_by_code: [],
    }),
    mutations: {
        updateAggregations (state, args) {
            // console.log("updateAggregations")
            // console.log(args.document_count_by_code);
            // console.log(args.document_count_by_affiliation_code);
            state.document_count_by_code = args.document_count_by_code;
            state.document_count_by_affiliation_code = args.document_count_by_affiliation_code;
            let document_count_by_area = [];
            for (const area of state.areas){
                let document_count = {
                    "area": area.area,
                    "doc_count": 0
                };
                for(const affiliation_code of area.affiliation_codes){
                    document_count["doc_count"] += args.document_count_by_affiliation_code.reduce((sum, item) => {
                        if (affiliation_code == item.affiliation_code){
                            return sum + item.doc_count;
                        } else {
                            return sum;
                        }
                    }, 0);
                }
                document_count_by_area.push(document_count);
            }
            state.document_count_by_area = document_count_by_area;
        }
    },
    getters: {
        getTotalDocumentCount (state){
            return state.document_count_by_affiliation_code.reduce((sum, item) => {
                return sum + item.doc_count;
            }, 0);
        },
        getDocumentCountsByCode (state){
            return state.document_count_by_code;
        },
        getDocumentCountByArea: (state) => (area_name) => {
            const index = state.document_count_by_area.findIndex(area => area.area === area_name);
            if (index >= 0){
                return state.document_count_by_area[index].doc_count;
            } else {
                return 0;
            }
        },
        /**
         * 都道府県コードを引数に、その都道府県コードに該当する検索ヒット文書数を返す。
         * @param {*} state 
         * @returns {integer} - 文書数を返す。該当する文書件数がない（0件）の場合、0を返す。
         */
        getDocumentCountByAffiliationCode: (state) => (affiliation_code) => {
            const index = state.document_count_by_affiliation_code.findIndex(item => item.affiliation_code === affiliation_code);
            if (index >= 0){
                return state.document_count_by_affiliation_code[index].doc_count;
            } else {
                return 0;
            }
        },
        /**
         * 団体コードを引数に、その団体コードに該当する検索ヒット文書数を返す。
         * @param {*} state 
         * @returns {integer} - 文書数を返す。該当する文書件数がない（0件）の場合、0を返す。
         */
        getDocumentCountByCode: (state) => (code) => {
            // console.log(code);
            // console.log("search_result_store.js", state);
            const index = state.document_count_by_code.findIndex(item => item.code === code);
            // console.log("index",index);
            if (index >= 0){
                return state.document_count_by_code[index].doc_count;
            } else {
                return 0;
            }
        },
        /**
         * 集計データの有無をBoolean型で返す。
         * @param {*} state 
         * @returns {boolean} - 集計データ（APIサーバから取得したaggregationデータ）がある場合、trueを返す。それ以外はfalseを返す。
         */
        haveDocumentCountByCode (state) {
            if (state.document_count_by_code.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
}

/**
 * 検索結果にヒットした文書を格納する。
 */
export const search_result = {
    state: () => ({
        document_count_keikakuhoshin: 0,
        document_keikakuhoshin: [],
        document_count_yosan: 0,
        document_yosan: [],
        document_count_kouhou: 0,
        document_kouhou: [],
        document_count_gikaigijiroku: 0,
        document_gikaigijiroku: [],
        document_count_sonota: 0,
        document_sonota: [],
    }),
    mutations: {
        updateDocumentCountKeikakuhoshin(state, document_count){
            state.document_count_keikakuhoshin = document_count;
        },
        updateDocumentCountYosan(state, document_count){
            state.document_count_yosan = document_count;
        },
        updateDocumentCountKouhou(state, document_count){
            state.document_count_kouhou = document_count;
        },
        updateDocumentCountGikaigijiroku(state, document_count){
            state.document_count_gikaigijiroku = document_count;
        },
        updateDocumentCountSonota(state, document_count){
            state.document_count_sonota = document_count;
        },
        updateDocumentKeikakuhoshin (state, documents){
            state.document_keikakuhoshin = documents;
        },
        updateDocumentYosan (state, documents){
            state.document_yosan = documents;
        },
        updateDocumentKouhou (state, documents){
            state.document_kouhou = documents;
        },
        updateDocumentGikaigijiroku (state, documents){
            state.document_gikaigijiroku = documents;
        },
        updateDocumentSonota (state, documents){
            state.document_sonota = documents;
        },
    },
    getters: {
        // ------------------------------
        // ドキュメント数を返す
        // ------------------------------
        getDocumentCountKeikakuhoshin(state){
            return state.document_count_keikakuhoshin;
        },
        getDocumentCountYosan(state){
            return state.document_count_yosan;
        },
        getDocumentCountKouhou(state){
            return state.document_count_kouhou;
        },
        getDocumentCountGikaigijiroku(state){
            return state.document_count_gikaigijiroku;
        },
        getDocumentCountSonota(state){
            return state.document_count_sonota;
        },
        // ------------------------------
        // ドキュメントを返す
        // ------------------------------
        getDocumentKeikakuhoshin(state){
            return state.document_keikakuhoshin;
        },
        getDocumentYosan(state){
            return state.document_yosan;
        },
        getDocumentKouhou(state){
            return state.document_kouhou;
        },
        getDocumentGikaigijiroku(state){
            return state.document_gikaigijiroku;
        },
        getDocumentSonota(state){
            return state.document_sonota;
        },
    },
}