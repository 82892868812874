<template>
<!--モーダル-->
<div id="modal_01" :class="['modal', { 'active': isActiveModal }]">
	<div class="bg"></div>
	<div class="white_box">
		<button class="btn_close" @click="closeModal">閉じる</button>
		<div class="modal_detail">
			<table>
				<tr>
					<th rowspan="3" class="genre">
						資料選択
						<span class="faq_icon">
							<span class="icon"></span>
							<span class="comment">・資料の開始と終了それぞれの年度の上限と下限を指定することで、資料年度の詳細な絞り込みができます。<br>・資料形式を指定することで、該当形式の資料のみ絞り込むことができます。</span>
						</span>
					</th>
					<th>
						対象年度開始
					</th>
					<td>
						<div class="flex_st_c">
							<select id="fiscal_year_start_min" v-model="fiscal_year_start_min" name="sort_type" class="sort">
								<option value="" selected>下限なし</option>
								<option v-for="choice in FISCAL_YEAR_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>

							<span>年度 〜</span>

							<select id="fiscal_year_start_max" v-model="fiscal_year_start_max" name="sort_type" class="sort">
								<option value="" selected>上限なし</option>
								<option v-for="choice in FISCAL_YEAR_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>
							<span>年度</span>
						</div>
					</td>
				</tr>
				<tr>
					<th>
						対象年度終了
					</th>
					<td>
						<div class="flex_st_c">
							<select id="fiscal_year_end_min" v-model="fiscal_year_end_min" name="sort_type" class="sort">
								<option value="" selected>下限なし</option>
								<option v-for="choice in FISCAL_YEAR_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>

							<span>年度 〜</span>

							<select id="fiscal_year_end_max" v-model="fiscal_year_end_max" name="sort_type" class="sort">
								<option value="" selected>上限なし</option>
								<option v-for="choice in FISCAL_YEAR_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>
							<span>年度</span>
						</div>
					</td>
				</tr>
				<tr>
					<th>
						形式
					</th>
					<td>
						<div class="flex_st_al">
							<dl class="row_dl">
								<dt>計画</dt>
								<dd class="flex_st_c label_check">
									<label v-for="choice in SUB_CATEGORY_CHOICES['計画']" :key="choice.value"><input type="checkbox" v-model="sub_category" :value="choice.value">{{ choice.label }}</label>
								</dd>
							</dl>
							<dl class="row_dl">
								<dt>予算</dt>
								<dd class="flex_st_c label_check">
									<label v-for="choice in SUB_CATEGORY_CHOICES['予算']" :key="choice.value"><input type="checkbox" v-model="sub_category" :value="choice.value">{{ choice.label }}</label>
								</dd>
							</dl>
						</div>
					</td>
				</tr>
				<tr class="line">
					<th rowspan="6" class="genre">
						自治体選択
						<span class="faq_icon">
							<span class="icon"></span>
							<span class="comment">都道府県市区町村区分、自治体の人口、財政力指数、エリア自治体の詳細な条件絞り込みができます。<br>※「財政力指数」とは、基準財政収入額を基準財政需要額で割った数値の過去3年間の平均値です。</span>
						</span>
					</th>
					<th>
						都道府県<br>市区町村区分
					</th>
					<td>
						<div class="label_check">
							<div>
								<label v-for="choice in TYPE_CHOICES.slice(0,1)" :key="choice"><input type="checkbox" v-model="types" :value="choice">{{ choice }}</label>
							</div>
							<div class="flex_st_al">
								<div>
									<label><input type="checkbox" v-model="selectedcity" @click="selectedCityAll" :value="choice">{{ "市" }}</label>
								</div>
								<div class="municipality_choice_label">
									<label v-for="choice in TYPE_CHOICES.slice(1,5)" :key="choice"><input type="checkbox" v-model="types" v-on:change="selectedCity" :value="choice">{{ choice }}</label>
								</div>
							</div>
							<div>
								<label v-for="choice in TYPE_CHOICES.slice(5,8)" :key="choice"><input type="checkbox" v-model="types" :value="choice">{{ choice }}</label>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<th>人口</th>
					<td>
						<div class="flex_st_c">
							<select v-model="population_min" name="sort_type" class="sort">
								<option value="" selected>下限なし</option>
								<option v-for="choice in POPULATION_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>

							<span>〜</span>

							<select v-model="population_max" name="sort_type" class="sort">
								<option value="" selected>上限なし</option>
								<option v-for="choice in POPULATION_CHOICES" :key="choice.value" :value="choice.value">{{ choice.label }}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<th>財政力指数</th>
					<td>
						<div class="flex_st_c">
							<select v-model="financial_index_min" name="sort_type" class="sort">
								<option value="" selected>下限なし</option>
								<option v-for="(choice, index) in FINANCIAL_INDEX_CHOICES" :key="index" :value="choice">{{ choice }}</option>
							</select>

							<span>〜</span>

							<select v-model="financial_index_max" name="sort_type" class="sort">
								<option value="" selected>上限なし</option>
								<option v-for="(choice, index) in FINANCIAL_INDEX_CHOICES" :key="index" :value="choice">{{ choice }}</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<th class="v_a">エリア・自治体</th>
					<td>
						<ul class="area_check_filter_list">
							<li class="active" v-for="area in AREA_CHOICES" :key="area.area">
								<MenuItemArea :area="area"/>
							</li>
						</ul>
					</td>
				</tr>
			</table>

			<div id="modal_bottom" class="flex_c">
				<!--button class="btn_terms_save">検索条件保存</button-->
				<button class="btn_side_filter_input active" @click="search">条件検索</button>
				<button class="btn_side_filter_clear" @click="uncheckAll">詳細条件クリア</button>
			</div>
		</div>
	</div>
</div>
</template>
        
<script>
import { ref } from 'vue';
import MenuItemArea from './MenuItemArea.vue'
import {AREA_CHOICES, TYPE_CHOICES, FINANCIAL_INDEX_CHOICES, POPULATION_CHOICES, FISCAL_YEAR_CHOICES, SUB_CATEGORY_CHOICES} from '@/components/FilterMenu/form_choices.js'


export default {
    name: 'ModalFilterMenu',
    data(){
        return {
			AREA_CHOICES: AREA_CHOICES,
			TYPE_CHOICES: TYPE_CHOICES,
			FINANCIAL_INDEX_CHOICES: FINANCIAL_INDEX_CHOICES,
			POPULATION_CHOICES: POPULATION_CHOICES,
			FISCAL_YEAR_CHOICES: FISCAL_YEAR_CHOICES,
			SUB_CATEGORY_CHOICES: SUB_CATEGORY_CHOICES,
			fiscal_year_start_min: "",
			fiscal_year_start_max: "",
			fiscal_year_end_min: "",
			fiscal_year_end_max: "",
			sub_category: [],
			types: [],
			population_min: "",
			population_max: "",
			financial_index_min: "",
			financial_index_max: "",
			selectedcity: false,
        }
    },
    props: {
        isActiveModal: {
            type: Boolean,
            required: true
        },
    },
    methods : {
		updateFiltering(){
			this.$store.commit("updateFiltering", {
				fiscal_year_start_min: this.fiscal_year_start_min,
				fiscal_year_start_max: this.fiscal_year_start_max,
				fiscal_year_end_min: this.fiscal_year_end_min,
				fiscal_year_end_max: this.fiscal_year_end_max,
				sub_category: this.sub_category,
				types: this.types,
				population_min: this.population_min,
				population_max: this.population_max,
				financial_index_min: this.financial_index_min,
				financial_index_max: this.financial_index_max,
				checked_codes: this.$store.getters.getCheckedOrganizationCodes,
				filtered_area_names: this.$store.getters.getCheckedAreaNames,
				filtered_affiliation_names: this.$store.getters.getCheckedAffiliationNames,
				filtered_organization_names: this.$store.getters.getCheckedOrganizationNames,
			});
		},
		search(){
			this.$emit("pressedModalSearch");
			this.$emit('close-modal');
		},
        // 全部ボタン
        selectedCityAll() {

            if (!this.selectedcity) {
                this.types.push("政令指定都市","中核市","施行時特例市","その他市","市");
                this.types = Array.from(new Set(this.types));
            } else {
                var removals = ["政令指定都市", "中核市", "施行時特例市", "その他市", "市"];
                this.types = this.types.filter(function (v) {
                    return !removals.includes(v);
                });
            }
            this.selectedcity = !this.selectedcity;
        },
        // クリック時
        selectedCity(e) {

            if ("政令指定都市" == e.target.value || "中核市" == e.target.value || "施行時特例市" == e.target.value || "その他市" == e.target.value) {
                const types = this.types;
                let citys = ["政令指定都市", "中核市", "施行時特例市", "その他市"];
                let selected = true;
                for (let i = 0; i < citys.length; i++) {
                    if (!types.includes(citys[i])) {
                        selected = false;
                        break;
                    }
				}

				if (selected) {
					this.types.push("市");
				} else {
					let number = this.types.indexOf("市");
					if ( 0 <= number ) {
                        this.types.splice(this.types.indexOf("市"), 1);
					}
                }

                this.selectedcity = selected;
			}
		},
        // ------------------------------
        // モーダルclose
        // ------------------------------
        closeModal(){
            this.$emit('close-modal');
        },
		uncheckAll: function(){
			this.fiscal_year_start_min = "";
			this.fiscal_year_start_max = "";
			this.fiscal_year_end_min = "";
			this.fiscal_year_end_max = "";
			this.sub_category = [];
			this.types = [];
			this.population_min = "";
			this.population_max = "";
			this.financial_index_min = "";
			this.financial_index_max = "";
            this.$store.commit("uncheckAll");
        },
    },
    // ------------------------------
    // Toggle Open and Close
    // ------------------------------
    setup() {
		const isActive = ref([false, false]); 
		const toggle = (index) => {
			isActive.value[index] = !isActive.value[index];
		};
		const isSubActive = ref([false, false]); 
		const subToggle = (index) => {
			isSubActive.value[index] = !isSubActive.value[index];
		}
		return {
			isActive,
			toggle,
			isSubActive,
			subToggle,
		};
	},
	components: {
		MenuItemArea,
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped>
.filter_menu_section {
    margin: 1rem 0 0 1rem;
}
</style> -->