<template>
    <!--side_filter-->
    <div id="side_filter" :style="{top: styleTop}">
        <div id="side_filter_title" class="flex_bw">
					<div class="flex_st">
						<h2 class="title_guide">エリア・自治体フィルタ</h2>
						<span class="faq_icon">
							<span class="icon"></span>
							<span class="comment">・初期状態（全てのチェックボックスに✓がない状態）では全ての自治体の資料が検索対象になります。１つ以上のチェックボックスにチェックがある場合、そのチェックされたエリア・都道府県・自治体を絞り込み対象として検索が行われます。<br><br>
・エリアのチェックボックスをチェックすると、エリア配下の都道府県・自治体のチェックボックスが自動的にチェックが入ります。都道府県のチェックボックスをチェックをすると、その都道府県配下の全ての自治体にチェックが入ります。</span>
						</span>
					</div>
                    <div ref="sideFilterBtn" id="side_filter_btn" class="[{'open' : isOpen}]" @click="closeFilter"></div>
		</div>
        <div class="side_filter_area">
            <ul class="area_check_filter_list">
                <li v-for="area in areas" :key="area.area" class="active">
                    <MenuItemByArea :area="area.area" :affiliation_codes="area.affiliation_codes" />
				</li>
            </ul>
            <div id="side_bottom" class="flex_bw_c">
                <button v-if="isEverUpdated" class="btn_side_filter_input active" @click="search">絞り込み</button>
                <button v-else class="btn_side_filter_input">絞り込み</button>
                <button class="btn_side_filter_clear" @click="uncheckAll">絞り込みクリア</button>
            </div>
        </div>
    </div>
</template>
        
<script>
import areas from "@/assets/data/areas.json"
import MenuItemByArea from "./MenuItemByArea.vue"
//import logger from "@/logger";


export default {
    name: 'ListFilterMenu',
    data(){
        return {
            areas: areas,
            isOpen: false,
        };
    },
    methods: {
        search(){
            this.$emit("pressedListFilterMenuSearch");
        },
        uncheckAll: function(){
            this.$store.commit("uncheckAll");
        },
        // ------------------------------
        // サイドフィルタクローズ
        // ------------------------------
        closeFilter(){
            //this.$emit('close-filter');
            window.$(this.$refs.sideFilterBtn).toggleClass('open');
            window.$(this.$refs.sideFilterBtn).closest('#container_box').toggleClass('close');
            window.$("footer").toggleClass('close');
            window.$("body").toggleClass('open');
        },
    },
    computed: {
        isEverUpdated(){
            return this.$store.getters.getIsEverUpdated;
        },
        styleTop(){
            return this.$store.getters.getHeaderHeight + "px";
        },
    },
    components: {
        MenuItemByArea,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.filter_menu_section {
    margin: 1rem 0 0 1rem;
}
</style>