<template>
    <footer id="footer">
      <div class="footer_link">
        <div class="element1">
          <a href="https://www.glocal-biz.jp/gfinder-agreement" target="_blank">
            <p class="link_rule">利用規約</p>
          </a>
        </div>
        <div class="element2">
          <a href="https://www.glocal-biz.jp/law" target="_blank">
            <p class="link_notion">特定商取引法に基づく表記</p>
          </a>
        </div>
        <div class="element3">
          <a href="https://www.glocal-biz.jp/privacypolicy" target="_blank">
            <p class="link_policy">プライバシーポリシー</p>
          </a>
        </div>
      </div>
      <p class="copyright">Copyright (C) 2024 Glocal Corp.</p>
    </footer>
</template>
<script>
export default {
  name: 'FooterSection',
}
</script>