import areas from '@/assets/data/areas.json'
export const AREA_CHOICES = areas;

export const TYPE_CHOICES = [
    "都道府県庁",
    "政令指定都市",
    "中核市",
    "施行時特例市",
    "その他市",
    "特別区",
    "町",
    "村",
]

/** 財政力指数 */
export const FINANCIAL_INDEX_CHOICES = [
    0.25,
    0.50,
    0.75,
    1.00,
    1.25,
    1.50,
    1.75,
    2.00,
    2.25,
    2.50,
]

export const POPULATION_CHOICES = [
    {"label": "5,000人", "value": 5000},
    {"label": "1万人", "value": 10000},
    {"label": "5万人", "value": 50000},
    {"label": "10万人", "value": 100000},
    {"label": "20万人", "value": 200000},
    {"label": "30万人", "value": 300000},
    {"label": "40万人", "value": 400000},
    {"label": "50万人", "value": 500000},
    {"label": "60万人", "value": 600000},
    {"label": "70万人", "value": 700000},
    {"label": "80万人", "value": 800000},
    {"label": "90万人", "value": 900000},
    {"label": "100万人", "value": 1000000},
]

const current_year = new Date().getFullYear();
const FISCAL_YEAR_MINIMAL_CHOICE = current_year - 15;
function generate_fiscal_year_choices(FISCAL_YEAR_MINIMAL_CHOICE){
	const current_year = new Date().getFullYear();
	const current_month = new Date().getMonth();
	let current_fiscal_year = current_year;
	if (current_month >= 6){
		current_fiscal_year = current_year + 1;
	}
	let years = [];
	let FISCAL_YEAR_CHOICES = [];
	for (let year = FISCAL_YEAR_MINIMAL_CHOICE; year <= current_fiscal_year; year++){
		const year_obj = new Date(`${year}-05-02T01:00:00`);
		years.push(year_obj);
	}
	years.forEach(year_obj => {
		const dt = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {era: 'long'});
		let [{ value: era }, { value: year_number }] = dt.formatToParts(year_obj);
		if (year_number == 1){
			year_number = "元";
		}
		const choice = {
				"label": `${ year_obj.getFullYear() }（${ era }${ year_number }）年`,
				"value": year_obj.getFullYear(),
			};
		FISCAL_YEAR_CHOICES.push(choice);
	});
	return FISCAL_YEAR_CHOICES;
}
export const FISCAL_YEAR_CHOICES = generate_fiscal_year_choices(FISCAL_YEAR_MINIMAL_CHOICE);


/** 資料形式 */
export const SUB_CATEGORY_CHOICES = {
    "計画": [
        {
            "label": "全体版",
            "value": 1,
        },
        {
            "label": "概要版",
            "value": 2,
        },
        {
            "label": "素案・案",
            "value": 3,
        },
    ],
    "予算": [
        {
            "label": "当初予算",
            "value": 5,
        },
        {
            "label": "補正予算",
            "value": 6,
        },
        {
            "label": "決算",
            "value": 8,
        },
    ],
}