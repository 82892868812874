<template>
    <label><input type="checkbox" name="" value="" id="" v-model="checked">{{ organization.name }} </label>
</template>
<script>
import { ref } from 'vue';


export default {
    name: 'MenuItemOrganization',
    props: {
        organization: Object,
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getOrganizationCheckedByCode(this.$props.organization.code);
            },
            set: function(checked_bool){
                this.$store.commit("updateOrganizationChecked", {code: this.$props.organization.code, checked: checked_bool});
            },
        },
    },
    /**
     * Toggle Open and Close
     */
    setup() {
        const isActive = ref([false, false]); 
        const toggle = (index) => {
            isActive.value[index] = !isActive.value[index];
        };
        const isSubActive = ref([false, false]); 
        const subToggle = (index) => {
            isSubActive.value[index] = !isSubActive.value[index];
        }
        return {
            isActive,
            toggle,
            isSubActive,
            subToggle,
        };
    },
}
</script>
