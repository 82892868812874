<template>
    <!-- <div class="loading-overlay" v-if="isLoading">loading</div> -->

    <ListFilterMenu ref="FilterMenu" @pressedListFilterMenuSearch="search" />
    <main id="result_card_box" :class="['tile_sort', { 'close' : isClose }, {'active' : isActive3} ]">
            <!--ワード検索-->
            <div id="center_search" :style="{top: styleTop}">
                <div class="form_01" v-show="searchBoxVisible">
                    <!-- <form> -->
                        <div class="flex_c">
                            <div class="search_keyword">
                                <label class="flex_st">
                                    <input type="text" v-model="and_query" placeholder='AND検索 例）窓口 "AI-OCR"' v-on:keypress.enter="search()">
                                    <input type="text" v-model="or_query" placeholder="OR検索 例）ヘルプデスク" v-on:keypress.enter="search()">
                                    <input type="text" v-model="not_query" placeholder="NOT検索 例）冊子 印刷" v-on:keypress.enter="search()">
                                </label>
                            </div>
                            <span class="faq_icon">
                                <span class="icon"></span>
                                <span class="comment">■すべてのキーワードを含める<br>・AND検索をする場合は、各キーワードをスペース（半角・全角）で区切り入力してください。
                                    <br>・完全一致させたいキーワードがある場合、””で囲み、各キーワードをスペース（半角・全角）で区切り、入力してください。
                                    <br>例:「”AAA” BBB “CCC”」
                                    <br><br>■いずれかのキーワードを含める<br>・OR検索をする場合は、各キーワードをスペース（半角・全角）で区切り入力してください。
                                    <br><br>■キーワードを含めない<br>・NOT検索（キーワードを含めない）をする場合、各キーワードをスペース（半角・全角）で区切り、入力してください。</span>
                            </span>
                            <button class="search" type="submit" aria-label="検索" v-on:click="search()">検索</button>
                            <button class="btn_detail_search" @click="modalOpen">詳細条件</button>

                        </div>
                    <!-- </form> -->
                    <div id="center_search_more" @click="formActive"></div>
                </div>
                <div :class="['form_02', { 'active': form2Active }]" >
                    <!--1次フェーズ時のソース-->
                    <div class="now_terms1">
                        <dl class="flex_bw_c">
                            <dt>現在の検索条件</dt>
                            <dd><p>{{ searchFilteringInText }}</p></dd>
                        </dl>
                    </div>
                    <!--/1次フェーズ時のソース-->
                    <!--2次フェーズ時のソース>
                    
                    <div class="now_terms2 flex_bw_c">
                        <select name="now_now_terms_option">
                            <option>現在の検索条件</option>
                            <option value="検索条件1">検索条件1</option>
                            <option value="検索条件2">検索条件2</option>
                            <option value="検索条件3">検索条件3</option>
                            <option value="検索条件4">検索条件4</option>
                        </select>
                        <div class="text">
                            <p>00 AND 00/完全一致/2022/2021/2020/最小人口●人/最大人口〇人/関東/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇〇〇市/〇〇市/〇〇市/〇〇市/〇〇市/〇〇〇〇市</p>
                        </div>
                        <button class="btn_terms_save">検索条件保存</button>
                    </div>
                    </2次フェーズ時のソース-->
                </div>


                <!--アンカーリンク / スイッチ-->
                <div :class="['anchor_switch_box', 'flex_bw', {'active' : isActive2}]">
                    <div class="anchor_link">
                        <ul class="flex_end_c">
                            <li><a @click="scrollToElement('category_01')">計画・方針</a></li>
                            <li><a @click="scrollToElement('category_02')">予算</a></li>
                            <li><a @click="scrollToElement('category_03')">広報</a></li>
                            <li><a @click="scrollToElement('category_04')">議会議事録</a></li>
                            <li><a @click="scrollToElement('category_05')">その他</a></li>
                        </ul>
                    </div>
                    <div class="switch_menu flex_st">
                        <div class="result_info"><span>自治体数</span> <strong>{{ getNumberOfOrganizationsHitInSearch }}</strong>/{{ getTotalNumberOfOrganizations }}</div>
                        <div class="result_info"><span>検索ヒット件数</span> <strong>{{ getHitDocumentCount }}</strong>件</div>
                        <!--select name="sort_type" class="sort">
                            <option>並び替え</option>
                            <option value="">名称 A-Z</option>
                            <option value="">名称 Z-A</option>
                            <option value="">更新日時 新</option>
                            <option value="">更新日時 古</option>
                            <option value="">データ量 大</option>
                            <option value="">データ量 小</option>
                            <option value="">ワード数 多</option>
                            <option value="">ワード数 少</option>
                        </select-->
                        <!-- <button class="btn_tile_sort active">タイル</button>
                        <button class="btn_carousel_sort">カルーセル</button> -->
                        <button :class="['btn_search', {'active' : isActive}]" @click="searchToggle">検索</button>
                    </div>
                </div>
                <!--/アンカーリンク / スイッチ-->
            </div>
            <!--/ワード検索-->
        <DocumentSection ref="DocumentSection"/>

    </main>
    <ModalFilterMenu ref="ModalFilterMenu" :isActiveModal="isActiveModal" @close-modal="closeModal" @pressedModalSearch="search" />
</template>

<script>
import DocumentSection from "./DocumentSection/DocumentSection.vue";
import ListFilterMenu from "@/components/FilterMenu/ListFilterMenu/ListFilterMenu.vue";
import ModalFilterMenu from "@/components/FilterMenu/ModalFilterMenu/ModalFilterMenu.vue"
import { SearchApiClient } from "@/client/search_api_client"
import { LogEventSearch } from "@/analytics";


export default {
    name: 'DocumentSearchHome',
    data(){
        return {
            and_query: "",
            or_query: "",
            not_query: "",
            searchBoxVisible: true,
            isActive: true,
            isClose: false,
            form2Active: false,
            isActive2: false,
            isActive3: false,
            isActiveModal: false,
        }
    },
    components: {
        ListFilterMenu,
        DocumentSection,
        ModalFilterMenu,
    },
    methods : {
        async search(){
            this.getDocuments();
            let google_analytics_logger = new LogEventSearch(
                this.$store.getters.getAndQuery,
                this.$store.getters.getOrQuery,
                this.$store.getters.getNotQuery,
                this.$store.getters.getFiscalYearStartMin,
                this.$store.getters.getFiscalYearStartMax,
                this.$store.getters.getFiscalYearEndMin,
                this.$store.getters.getFiscalYearEndMax,
                this.$store.getters.getSubCategory,
                this.$store.getters.getTypes,
                this.$store.getters.getPopulationMin,
                this.$store.getters.getPopulationMax,
                this.$store.getters.getFinancialIndexMin,
                this.$store.getters.getFinancialIndexMax,
                this.$store.getters.getCheckedLocalGovernment,
            );
            try {
                google_analytics_logger.log_event();
            } catch (error) {
                console.log("DocumentSection.select_content", error);
            }
        },
        async getDocuments(){
            this.$store.commit('startLoading');

            const token = await this.$auth0.getAccessTokenSilently(); // tokenを取得する
            // const token = await this.$auth0.getAccessTokenSilently({
            //     authorizationParams: {
            //     audience: process.env.VUE_APP_AUTH0_AUDIENCE,
            //     },
            // }); // tokenを取得する
            const headers = { headers: { Authorization: 'Bearer ' + token }};
            this.$store.commit("updateQueryText", {
                and_query: this.and_query,
                or_query: this.or_query,
                not_query: this.not_query,
            });
            this.$refs.ModalFilterMenu.updateFiltering(); // 設定された検索条件を保存し、検索条件で検索要求ができるように準備する。
            const client = new SearchApiClient(
                headers,
                this.and_query,
                this.or_query,
                this.not_query,
                this.$store.state.search_filtering.fiscal_year_start_min,
                this.$store.state.search_filtering.fiscal_year_start_max,
                this.$store.state.search_filtering.fiscal_year_end_min,
                this.$store.state.search_filtering.fiscal_year_end_max,
                this.$store.state.search_filtering.sub_category,
                this.$store.getters.filterOrganizationCodes,
            )
            // await this.getAggregations(client);
            // await this.getDocumentsKeikakuhoshin(client);
            // await this.getDocumentsYosan(client);
            // await this.getDocumentsKouhou(client);
            // await this.getDocumentsGikaigijiroku(client);
            // await this.getDocumentsSonota(client);
            // alert(this.$store.getters.getFilteringInText);
            await Promise.all([
                this.getAggregations(client),
                this.getDocumentsKeikakuhoshin(client),
                this.getDocumentsYosan(client),
                this.getDocumentsKouhou(client),
                this.getDocumentsGikaigijiroku(client),
                this.getDocumentsSonota(client),
            ]);

            this.$store.commit('stopLoading');// ローディング終了
        },
        /**
         * 
         * @param {Object SearchApiClient} client 
         */
        async getAggregations(client){
            const aggregation = await client.getAggregations("/local/documents/aggregations");
            this.$store.commit("updateAggregations", {
                document_count_by_code: aggregation.total_documents_by_code,
                document_count_by_affiliation_code: aggregation.total_documents_by_affiliation_code,
            })
        },
        async getDocumentsKeikakuhoshin(client){
            const search_result_keikakuhoshin = await client.getDocuments("/local/documents/keikakuhoshin");
            console.log(search_result_keikakuhoshin);
            this.$store.commit("updateDocumentCountKeikakuhoshin", search_result_keikakuhoshin.hits.total);
            this.$store.commit("updateDocumentKeikakuhoshin", search_result_keikakuhoshin.hits.hits);
        },
        async getDocumentsYosan(client){
            const search_result_yosan = await client.getDocuments("/local/documents/yosankessan");
            this.$store.commit("updateDocumentCountYosan", search_result_yosan.hits.total);
            this.$store.commit("updateDocumentYosan", search_result_yosan.hits.hits);
        },
        async getDocumentsKouhou(client){
            const search_result_kouhou = await client.getDocuments("/local/documents/kouhou");
            this.$store.commit("updateDocumentCountKouhou", search_result_kouhou.hits.total);
            this.$store.commit("updateDocumentKouhou", search_result_kouhou.hits.hits);
        },
        async getDocumentsGikaigijiroku(client){
            const search_result_gikaigijiroku = await client.getDocuments("/local/documents/iinkaigijiroku");
            this.$store.commit("updateDocumentCountGikaigijiroku", search_result_gikaigijiroku.hits.total);
            this.$store.commit("updateDocumentGikaigijiroku", search_result_gikaigijiroku.hits.hits);
        },
        async getDocumentsSonota(client){
            const search_result_sonota = await client.getDocuments("/local/documents/sonota");
            console.log(search_result_sonota);
            this.$store.commit("updateDocumentCountSonota", search_result_sonota.hits.total);
            this.$store.commit("updateDocumentSonota", search_result_sonota.hits.hits);
        },
        //  ----------------------------
        //  特定要素に移動
        //  ---------------------------- 
        scrollToElement(elementId) {
            const element = document.getElementById(elementId); //移動する要素
            if (element) {
                const offset = 50; //追加で移動するpixel数
                const { top } = element.getBoundingClientRect(); //要素の上部と画面のtopまでの距離
                const pageY = window.pageYOffset; //現在見える画面の上部とブラウザーのトップ間の距離
                const scrollToY = top + pageY - offset;
                window.scrollTo({
                    top: scrollToY,
                    behavior: 'smooth'
                });
            }
        },
        //  ----------------------------
        //  スクロール時検索ボックスhidden
        //  ---------------------------- 
        handleScroll() {
            // スクロール位置によって検索を格下り表示
            if (window.scrollY > 0) {
            this.searchBoxVisible  = false;   
            } else {
            this.isActive = true;
            this.searchBoxVisible  = true;   
            this.isClose = false;         
            }
        },
        // ------------------------------
        // 検索条件エリア表示
        // ------------------------------
        searchToggle() {
            this.isActive = !this.isActive;
            this.searchBoxVisible = !this.searchBoxVisible;
            this.isClose = !this.isClose;
        },
        // ------------------------------
        // 中央サーチスイッチ
        // ------------------------------
        formActive() {
            this.form2Active = !this.form2Active;
            this.isActive = !this.isActive;
            this.isActive2 = !this.isActive2;
            this.isActive3 = !this.isActive3;
        },
        // ------------------------------
        // 詳細条件モーダル
        // ------------------------------
        modalOpen(){
            // console.log('モダルbuttonクリック!');
            this.isActiveModal = true;
        },
        closeModal(){
            this.isActiveModal = false;
        },
        // ------------------------------
        // senter_search top動的 NavBar.vueからヘッダタグの高さの値を受信
        // ------------------------------
        // updateCenterSearchPosition(height) {
        //     const centerSearch = document.querySelector("#center_search");
        //     console.log("navbar_height:",height);
        //     // alert("Home.vue:updateCenterSearchPositionメソッド");
        //     if (centerSearch) {
        //         const topValue = height + 0; // topにheightを適用
        //         centerSearch.style.top = topValue + "px";
        //     }
        // },
    },
    computed: {
        searchFilteringInText: function() {
            const search_filtering_text = this.$store.getters.getFilteringInText;
            if(search_filtering_text===""){
                return "検索条件は指定されていません。";
            } else {
                return search_filtering_text;
            }
        },
        styleTop(){
            return this.$store.getters.getHeaderHeight + "px";
        },
        getOrganizationCodesHitInSearch(){
            let organizations_hit_in_search = [];
            const filtered_organization_codes = this.$store.getters.filterOrganizationCodes;
            //console.log(filtered_organization_codes)
            const document_counts_by_code = this.$store.getters.getDocumentCountsByCode;
            //console.log(document_counts_by_code)
            if(filtered_organization_codes.length <= 0) {return document_counts_by_code;}
            for (const document_count_object of document_counts_by_code){
                if(filtered_organization_codes.includes(document_count_object.code)){
                    organizations_hit_in_search.push(document_count_object);
                }
            }
            return organizations_hit_in_search;
        },
        getNumberOfOrganizationsHitInSearch(){
            const organization_codes_hit_in_search = this.getOrganizationCodesHitInSearch;
            return organization_codes_hit_in_search.length;
        },
        getTotalNumberOfOrganizations(){
            return this.$store.getters.getNumberOfOrganizations;
        },
        getHitDocumentCount(){
            let hit_document_count = 0;
            const organization_codes_hit_in_search = this.getOrganizationCodesHitInSearch;
            // console.log("Home.getHitDocumentCount",organization_codes_hit_in_search);
            organization_codes_hit_in_search.forEach((organization_code) => {
                hit_document_count += organization_code.doc_count;
            });
            return hit_document_count;
        },
    },
    created() {
        this.getDocuments();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style>
.hidden {
  display: none;
}
</style>