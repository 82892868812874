import { createRouter, createWebHashHistory } from "vue-router"
//import { createRouter, createWebHistory } from "vue-router"
import DocumentSearchHome from "@/components/Home.vue"
import FooterSection from "@/components/Footer/Footer.vue"
import { createAuthGuard } from "@auth0/auth0-vue";


const routes = [
    //{ path: '', name: "DocumentSearchHome", component: DocumentSearchHome },
    { 
      path: '/local', 
      name: "DocumentSearchHome", 
      components: {
        default: DocumentSearchHome,
        footer: FooterSection,
      },
      beforeEnter: createAuthGuard(),
    },
    { 
      path: '', 
      name: "DocumentSearchHome", 
      components: {
        default: DocumentSearchHome,
        footer: FooterSection,
      },
      beforeEnter: createAuthGuard(),
    },
    //{ path: '/app', component: App },
  ]
  
// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    //history: createWebHistory(),
    routes, // short for `routes: routes`
})
  