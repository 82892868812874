/** 削除 */
class Logger {
    constructor(){
        this.init();
    }
    init = () => {
        if (process.env.VUE_APP_ENV !== 'production'){
            this.log = console.log.bind(console);
            this.debug = console.debug.bind(console);
            this.info = console.info.bind(console);
            this.warn = console.warn.bind(console);
            this.error = console.error.bind(console);
            this.logToServer = this.error;
            //this.logToServer = () => {};
        } else {
            this.log = this.debug = this.info = this.warn = this.error = () => {};
            this.logToServer = err => {
                // 用意出来次第、ログサーバーに投げるコードを書く。以下コードも削除すること。
                if(process.env.NODE_ENV !== 'production'){
                    console.error(err);
                }
            };
        }
    }
}

const logger = new Logger();

export default logger;