// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase_app);


export class LogEventSearch {
    constructor(
        and_search_term,
        or_search_term, 
        not_search_term,
        fiscal_year_start_min,
        fiscal_year_start_max,
        fiscal_year_end_min,
        fiscal_year_end_max,
        document_sub_category,
        city_types,
        population_min,
        population_max,
        financial_index_min,
        financial_index_max,
        checked_local_government,
        // filtered_area_names,
        // filtered_affiliation_names,
        // filtered_organization_names,
    ){
        this.and_search_term = and_search_term;
        this.or_search_term = or_search_term;
        this.not_search_term = not_search_term;
        this.fiscal_year_start_min = fiscal_year_start_min;
        this.fiscal_year_start_max = fiscal_year_start_max;
        this.fiscal_year_end_min = fiscal_year_end_min;
        this.fiscal_year_end_max = fiscal_year_end_max;
        this.document_sub_category = document_sub_category;
        this.city_types = city_types;
        this.population_min = population_min;
        this.population_max = population_max;
        this.financial_index_min = financial_index_min;
        this.financial_index_max = financial_index_max;
        this.checked_local_government = checked_local_government;
        // this.filtered_area_names = filtered_area_names;
        // this.filtered_affiliation_names = filtered_affiliation_names;
        // this.filtered_organization_names = filtered_organization_names;
    }
    log_event(){
        logEvent(analytics, "bunsyo_local_search", {
            search_type: "local_bunsyo",
            and_search_term: this.and_search_term,
            or_search_term: this.or_search_term,
            not_search_term: this.not_search_term,
            fiscal_year_start_min: this.fiscal_year_start_min,
            fiscal_year_start_max: this.fiscal_year_start_max,
            fiscal_year_end_min: this.fiscal_year_end_min,
            fiscal_year_end_max: this.fiscal_year_end_max,
            document_sub_category: this.document_sub_category,
            city_types: this.city_types,
            population_min: this.population_min,
            population_max: this.population_max,
            financial_index_min: this.financial_index_min,
            financial_index_max: this.financial_index_max,
            checked_local_government: this.checked_local_government,
            // filtered_area_names: this.filtered_area_names,
            // filtered_affiliation_names: this.filtered_affiliation_names,
            // filtered_organization_names: this.filtered_organization_names,
        });
        logEvent(analytics, "search", {
            search_type: "local_bunsyo",
            and_search_term: this.and_search_term,
            or_search_term: this.or_search_term,
            not_search_term: this.not_search_term,
            fiscal_year_start_min: this.fiscal_year_start_min,
            fiscal_year_start_max: this.fiscal_year_start_max,
            fiscal_year_end_min: this.fiscal_year_end_min,
            fiscal_year_end_max: this.fiscal_year_end_max,
            document_sub_category: this.document_sub_category,
            city_types: this.city_types,
            population_min: this.population_min,
            population_max: this.population_max,
            financial_index_min: this.financial_index_min,
            financial_index_max: this.financial_index_max,
            checked_local_government: this.checked_local_government,
            // filtered_area_names: this.filtered_area_names,
            // filtered_affiliation_names: this.filtered_affiliation_names,
            // filtered_organization_names: this.filtered_organization_names,
        });
    }
}


export class LogEventSelectContent {
    constructor(
        file_id,
        file_page,
        category,
        sub_category,
        title,
        code,
        row_column,
    ){
        this.file_id = file_id;
        this.file_page = file_page;
        this.category = category;
        this.sub_category = sub_category;
        this.title = title;
        this.code = code;
        if(row_column["column"] < 1 || row_column["row"] < 1){
            throw new Error("Column and row must be greater than 0.");
        }
        this.row = row_column["row"];
        this.column = row_column["column"];
        console.log("analytics", row_column);
    }
    log_event(){
        logEvent(analytics, "select_content", {
            content_type: "local_bunsyo",
            file_id: this.file_id,
            file_page: this.file_page,
            content_category: this.category,
            content_sub_category: this.sub_category,
            title: this.title,
            code: this.code,
            row: this.row,
            column: this.column,
        });
    }
}