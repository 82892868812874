/**
 * メインセクション（エリア・自治体フィルタリング、検索欄、検索結果表示）の高さを調整するために、Headerの高さを管理する。
 */
export const header_height_control = {
    state: () => ({
        header_height: 0,
    }),
    mutations: {
        updateHeaderHeight (state, height) {
            state.header_height = height;
        },
    },
    getters: {
        getHeaderHeight (state) {
            return state.header_height;
        },
    },
}