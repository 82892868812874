<template>
    <li class="active">
        <p><label><input type="checkbox" name="" value="" id="" v-model="checked">{{ area.area }} </label></p>
        <div @click="toggle(0)"  :class="['area_more', { 'active': isActive[0] }]"></div>
        <ul v-show="isActive[0]" style="display: block;">
            <li v-for="affiliation in area.affiliations" :key="affiliation">
                <MenuItemAffiliation :affiliation="getAffiliationByName(affiliation)" />
            </li>
        </ul>
    </li>
</template>
<script>
import { ref } from 'vue';
import MenuItemAffiliation from './MenuItemAffiliation.vue';

export default {
    name: 'MenuItemArea',
    props: {
        area: Object,
    },
    methods: {
        getAffiliationByName: function(affiliation_name){
            return this.$store.getters.getAffiliationByName(affiliation_name)
        },
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getAreaCheckedByName(this.$props.area.area);
            },
            set: function(checked_bool){
                this.$store.commit("updateAreaChecked", {area_name: this.$props.area.area, checked: checked_bool});
            },
        },
    },
    /**
     * Toggle Open and Close
     */
    setup() {
        const isActive = ref([false, false]); 
        const toggle = (index) => {
            isActive.value[index] = !isActive.value[index];
        };
        const isSubActive = ref([false, false]); 
        const subToggle = (index) => {
            isSubActive.value[index] = !isSubActive.value[index];
        }
        return {
            isActive,
            toggle,
            isSubActive,
            subToggle,
        };
    },
    components: {
        MenuItemAffiliation,
    },
}
</script>