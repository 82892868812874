<template>
  <div class="popup-bg-cover"></div>
  <div class="error_message">
    <div class="card card-skin">
      <div class="card__textbox">
        <div class="card__titletext">
          {{ message.title }}
        </div>
        <div class="card__overviewtext">
          {{ message.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorActions from "./ErrorActions.json"
export default {
  name: 'ErrorMessage',
  data(){
    return {
      default_title: "コンテンツにアクセスできませんでした",
      default_message: "ご迷惑をおかけして申し訳ございません。\n更新してもう一度お試しください。",
    };
  },
  props: {
    errorCaptured: Boolean,
    error: Object,
  },
  computed: {
    message(){
      const error = this.error;
      for (const error_action of ErrorActions){
        if(error_action.error_codes.includes(error.code)){
          return error_action
        }
      }
      return {
        title: this.default_title,
        message: this.default_message
      }
    },
  },
}
</script>
<style scoped>
.popup-bg-cover{
	position: fixed; /* ブラウザの定位置に固定 */
	background: whitesmoke; /* 背景色を半透明の黒色に */
	width: 100%; /* 要素の横幅を画面全体に */
	height: 100%; /* 要素の高さを画面全体に */
	top: 0; /* 要素の固定位置をブラウザ最上部に合わせる */
	left: 0; /* 要素の固定位置をブラウザ左側に合わせる */
	/*z-index: 1000; /* 要素をコンテンツより前面に（要調整） */
}

.error_message {
	margin: 50vh auto 0;
	transform: translateY(-50%);
	padding: 15px 30px;
	text-align: center;
	width: 50vh;
  z-index: 1000; /* 要素をコンテンツより前面に（要調整） */
}


.card{
  width: 50vh;
  height: auto;
  z-index: 1001; /* 要素をコンテンツより前面に（要調整） */
}
.card__imgframe{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(https://placehold.jp/640x360.png) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card__textbox{
  width: 100%;
  height: auto;
  padding: 20px 18px;
  background: #ffffff;
  box-sizing: border-box;
}
.card__textbox > * + *{
  margin-top: 10px;
}
.card__titletext{
  font-size: 20px;
  font-weight: bold;
  line-height: 125%;
}
.card__overviewtext{
  font-size: 12px;
  line-height: 150%;
}

.card-skin{
  overflow: hidden;
  border-radius: 8px;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-bottom: solid 5px #888;
}
</style>