import { createStore } from 'vuex'
import { aggregation, search_result } from './search_result_store'
import { filtering_area_organization, search_filtering } from './search_filtering_store'
import { header_height_control } from './header_height_store'
import { search_loading } from './search_loading_store'



export const store = createStore({
    modules: {
        aggregation,
        search_result,
        filtering_area_organization,
        search_filtering,
        header_height_control,
        search_loading,
    }
})