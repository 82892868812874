export const search_loading = {
    state: () => ({
      searchLoading: false,
    }),
    mutations: {
      startLoading(state) {
        state.searchLoading = true;
      },
      stopLoading(state) {
        state.searchLoading = false;
      }
    },
    getters: {
      searchLoading (state) {
        return state.searchLoading;
    },
    }
  }
  