import axios from "axios";
import { CONFIG } from "@/config"
import logger from "@/logger";


import dummy_aggregation from "@/assets/data/test/dummy_aggregation.json"
import dummy_search_result from "@/assets/data/test/dummy_document_search_result.json"

const instance = axios.create(CONFIG);

export class SearchApiClient{
    constructor(headers, and_query, or_query, not_query, fiscal_year_start_min, fiscal_year_start_max, fiscal_year_end_min, fiscal_year_end_max, sub_category, code){
        this.headers = headers;
        this.and_query = and_query;
        this.or_query = or_query;
        this.not_query = not_query;
        this.fiscal_year_start_min = fiscal_year_start_min;
        this.fiscal_year_start_max = fiscal_year_start_max;
        this.fiscal_year_end_min = fiscal_year_end_min;
        this.fiscal_year_end_max = fiscal_year_end_max;
        this.sub_category = sub_category;
        this.code = code;
    }
    cleanQuery(query){
        let cleaned_query = query;
        cleaned_query = cleaned_query.replace(/\s/g, " ");
        cleaned_query = cleaned_query.replace(/'/g, '"');
        cleaned_query = cleaned_query.replace(/’/g, '"');
        cleaned_query = cleaned_query.replace(/”/g, '"');
        return cleaned_query;
    }
    getRequestBody(){
        let request_body = {};
        if(this.and_query!==undefined&&this.and_query!==null&&this.and_query!==""){
            request_body.and_query = this.cleanQuery(this.and_query);
        }
        if(this.or_query!==undefined&&this.or_query!==null&&this.or_query!==""){
            request_body.or_query = this.cleanQuery(this.or_query);
        }
        if(this.not_query!==undefined&&this.not_query!==null&&this.not_query!==""){
            request_body.not_query = this.cleanQuery(this.not_query);
        }
        if(this.fiscal_year_start_min!==undefined&&this.fiscal_year_start_min!==null&&this.fiscal_year_start_min!==""){
            request_body.fiscal_year_start_min = this.fiscal_year_start_min;
        }
        if(this.fiscal_year_start_max!==undefined&&this.fiscal_year_start_max!==null&&this.fiscal_year_start_max!==""){
            request_body.fiscal_year_start_max = this.fiscal_year_start_max;
        }
        if(this.fiscal_year_end_min!==undefined&&this.fiscal_year_end_min!==null&&this.fiscal_year_end_min!==""){
            request_body.fiscal_year_end_min = this.fiscal_year_end_min;
        }
        if(this.fiscal_year_end_max!==undefined&&this.fiscal_year_end_max!==null&&this.fiscal_year_end_max!==""){
            request_body.fiscal_year_end_max = this.fiscal_year_end_max;
        }
        if(Array.isArray(this.sub_category)&&this.sub_category.length>0){
            request_body.sub_category = this.sub_category;
        }
        return request_body;
    }
    getRequestBodyForAggregations(){
        return this.getRequestBody();
    }
    getRequestBodyForDocuments(){
        let request_body = this.getRequestBody();
        if(Array.isArray(this.code)&&this.code.length>0){
            request_body.code = this.code;
        }
        return request_body;
    }
    cleanResponse(response){
        if(response.status==200) return response;
        let error = new Error(response.data.error_message)
        error.code = response.data.error_code;
        throw error;
    }
    async getAggregations(endpoint){
        const request_body = this.getRequestBodyForAggregations();
        logger.log(request_body);
        try {
            const response = await instance.post(endpoint, request_body, this.headers);
            //logger.log(response)
            this.cleanResponse(response);
            return response.data;
        } catch (error) {
            logger.logToServer(error);
            throw error;
        }
    }
    async getDocuments(endpoint){
        const request_body = this.getRequestBodyForDocuments();
        console.log("search_api_client", request_body);
        try {
            const response = await instance.post(endpoint, request_body, this.headers);
            console.log("search_api_client", response)
            this.cleanResponse(response);
            return response.data;
        } catch (error) {
            logger.logToServer(error);
            throw error;
        }
    }
    dummy_getAggregations(endpoint){
        console.log(endpoint);
        // console.log(this.getRequestBodyForAggregations());
        return dummy_aggregation;
    }
    dummy_getDocuments(endpoint){
        console.log(endpoint);
        // console.log(this.getRequestBodyForDocuments());
        return dummy_search_result;
    }
}
