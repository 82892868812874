<template>
  <div v-if="errorCaptured">
    <ErrorMessage :errorCaptured="errorCaptured" :error="error"/>
  </div>
  <div class="loading-overlay" v-if="isLoading||!isAuthenticated">
    <div class="sk-chase">
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
		</div>
  </div>
  <div id="top" :style="{ 'font-size': fontSizeStyle }" v-else>
    <NavBar :isLoading="isLoading" :isAuthenticated="isAuthenticated"  @sizeButtonClick="handleFontSizeButtonClick" @height-updated="updateContainerPadding"/>
    <!--container-->
    <section id="container_box" :style="{paddingTop: stylePaddingTop}">
      <div  class="container">
        <router-view></router-view>
      </div>
    </section>
    <!--/container-->
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue"
import ErrorMessage from "@/components/Error/ErrorMessage.vue"
import ErrorActions from "@/components/Error/ErrorActions.json"
import { ref } from 'vue';


export default {
  name: 'App',
  components: {
    NavBar,
    ErrorMessage,
  },
  data() {
    return {
      className: '',
      isAuthenticated: this.$auth0.isAuthenticated,
      isLoading: this.$auth0.isLoading,
      errorCaptured: false,
      error: false,
    };
  },
  computed: {
    stylePaddingTop(){
      return this.$store.getters.getHeaderHeight + 6 + "px";
    },
  },
  methods: {
    // ------------------------------
    // ヘッダ分スペースを開ける
    // ------------------------------
    updateContainerPadding(height) {
      this.containerStyles.paddingTop = height + 6 + 'px';
      console.log("header_padding_top:",this.containerStyles.paddingTop);
    }
  },
  // ------------------------------
  // フォントサイズの変更
  // ------------------------------
  setup() {
    // const containerBoxPaddingTop = ref('0px');
    const fontSizeStyle = ref('100%');

    // const handleSizeButtonClick = (size) => {
    //   const headerHeight = document.querySelector("header").offsetHeight;
    //   if (size === 'small') {
    //     // containerBoxPaddingTop.value = '6';
    //     console.log("small");
    //     containerBoxPaddingTop.value = `${headerHeight + 6}px`;
    //   } else if (size === 'medium') {
    //     // containerBoxPaddingTop.value = '12';
    //     containerBoxPaddingTop.value = `${headerHeight + 12}px`;
    //   } else if (size === 'large') {
    //     // containerBoxPaddingTop.value = '18';
    //     containerBoxPaddingTop.value = `${headerHeight + 18}px`;
    //   }
    // };

    const handleFontSizeButtonClick = (size) => {
      if (size === 'small') {
        console.log("smallfont");
        fontSizeStyle.value = '80%';
      } else if (size === 'medium') {
        fontSizeStyle.value = '100%';
      } else if (size === 'large') {
        fontSizeStyle.value = '120%';
      }
    };

    return {
      // containerBoxPaddingTop,
      // handleSizeButtonClick,
      fontSizeStyle,
      handleFontSizeButtonClick,
    };

  },
  created(){
    console.group();
    console.log("isLoading", this.isLoading);
    console.log("isAuthenticated", this.isAuthenticated);
    console.groupEnd();
  },
  errorCaptured(error) {
    console.log(error)
    for (const error_action of ErrorActions){
      if(error_action.error_codes.includes(error.code)){
        this.error = error;
        this.errorCaptured = true;
        return false;
      }
    }
  },
}
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 要素をコンテンツより前面に（要調整） */
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}
</style>
<!-- <style>
@import './assets/style/style.css';

</style> -->
<!-- <style>
  .container{
    width:1200px; 
    max-width:none!important;
  }

  ul{
    list-style: none;
  }

  li {
    display: list-item;
    /* text-align: 
  }

  .ais-Hits-item {
    /* margin-bottom: 1em; */
    /* width: calc(100% - 1rem); */
  } 

  .hit-sub {
    font-size: small;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hit-sub1 {
    font-size: small;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*タイトル */
  .searchtitle {
    position: relative;
    margin-right: 70px;
    padding: 0.2rem 0rem 0.5rem 1rem;
    word-break: break-all;
    font-weight: bold;
    background: #f4f4f4;
  }
  
  /*ページ表示 */
  .searchtitle span{
    font-size: 16px;
    font-style: italic;
    font-family: Georgia;
    position: absolute;
    top: 0;
    right: -70px;
    display: block;
    padding-top: 1rem;
    padding-left: 16px;
    color: #000;
  }

  /*タグ */
  .hit-tag{
    font-size: small;
    display: inline-block;
    margin: 0 0.1em 0.6em 0;
    padding: 0.05rem 0.5rem 0.05rem 0.5rem;
    line-height: 1;
    text-decoration: none;
    color: darkgreen;
    background-color: #eeeeee;
    border: 1px solid darkgreen;
    border-radius: 1em;
  }

  /*検索*/
  .searchbox{
    /* -webkit-appearance: none;
    -moz-appearance: none; */
    appearance: none;
    padding: 0.3rem 1.7rem;
    width: 500px;
    /* position: relative; */
    background-color: #fff;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
  }



  /*-------------------------- */

  #nav {
   padding: 30px;
  }
   
  #nav a {
   font-weight: bold;
   color: #2c3e50;
  }
   
  #nav a.router-link-exact-active {
   color: #42b983;
  }

  .menu{
    background: darkslateblue;
    padding: 15px;
    border-radius: 5px;
  }

  .menu a{
    color: white;
    padding: 10px;
  }

  .room-img {
    width:100%;
    margin-top: 40px;
  }

  </style> -->
