import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store/store'
import { router } from "./router/router"
import { createAuth0 } from '@auth0/auth0-vue';
import * as Sentry from "@sentry/vue";

//import { loadFonts } from './plugins/webfontloader'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.bundle.min.js" 
import './assets/style/style.css'

//import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components/VExpansionPanel'
// import * as directives from 'vuetify/directives'

// const vuetify = createVuetify({
//   components,
//   directives,
// })


import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

const APP_VERSION = require('../package.json').version

const app = createApp(App)

if ("production" !== process.env.VUE_APP_ENV) {
  console.log("version: "+APP_VERSION);
  console.log(process.env);
}

if ("local" !== process.env.VUE_APP_ENV){
  Sentry.init({
      app,
      dsn: "https://7094df10657f4f71a9d0b6252ea5bc20@o4504960860094464.ingest.sentry.io/4504960871890944",
      // release: "gfinder@" + APP_VERSION,
      environment: process.env.VUE_APP_ENV,
      debug: process.env.VUE_APP_SENTRY_DEBUG_MODE,  // In production env, it must be false.
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
          blockAllMedia: true,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: process.env.VUE_APP_tracesSampleRate,
      tracePropagationTargets: [
        "localhost",
        /https:\/\/gfinder-dev.glocal-dev.com/,
        /https:\/\/gfinder-stg.glocal-dev.com/,
        /https:\/\/www.gfinder.jp/,
      ],
      profilesSampleRate: process.env.VUE_APP_profilesSampleRate, // プロファイルのサンプリング率
      replaysSessionSampleRate: process.env.VUE_APP_replaysSessionSampleRate, // セッションのリプレイのサンプリング率
      replaysOnErrorSampleRate: process.env.VUE_APP_replaysOnErrorSampleRate, // エラー時のリプレイのサンプリング率
  });
}

app.use(
  createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    }
  })
);

app.use(store)
app.use(router)
// app.use(vuetify)
app.mount('#app')

// app.config.errorHandler = (error) => {
//   console.log(error)
//   console.log("error captured")
// }