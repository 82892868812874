<template>
    <p><label><input type="checkbox" name="" value="" id="" v-model="checked">{{ affiliation.affiliation }} </label></p>
    <div @click="subToggle(0)"  :class="['area_more', { 'active': isSubActive[0] }]"></div>
    <ul class="label_check" v-show="isSubActive[0]" style="display: block;">
        <li v-for="organization in getOrganizationsByAffiliationCode(affiliation.affiliation_code)" :key="organization.code">
            <MenuItemOrganization :organization="organization"/>
        </li>
    </ul>
</template>
<script>
import { ref } from 'vue';
import MenuItemOrganization from './MenuItemOrganization.vue';

export default {
    name: 'MenuItemAffiliation',
    props: {
        affiliation: Object,
    },
    methods: {
        getOrganizationsByAffiliationCode: function(affiliation_code){
            return this.$store.getters.getOrganizationsByAffiliationCode(affiliation_code);
        },
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getAffiliationCheckedByCode(this.$props.affiliation.affiliation_code);
            },
            set: function(checked_bool){
                this.$store.commit("updateAffiliationChecked", {affiliation_code: this.$props.affiliation.affiliation_code, checked: checked_bool});
            },
        },
    },
    /**
     * Toggle Open and Close
     */
    setup() {
        const isActive = ref([false, false]); 
        const toggle = (index) => {
            isActive.value[index] = !isActive.value[index];
        };
        const isSubActive = ref([false, false]); 
        const subToggle = (index) => {
            isSubActive.value[index] = !isSubActive.value[index];
        }
        return {
            isActive,
            toggle,
            isSubActive,
            subToggle,
        };
    },
    components: {
        MenuItemOrganization,
},
}
</script>
